
import React from 'react'
import PropTypes from 'prop-types'

const font = {
  h1: 'font-serif font-normal leading-tight md:leading-snug text-h2/m xl:text-h1',
  h2: 'font-serif font-normal leading-tight md:leading-snug text-h2/m xl:text-h2',
  h3: 'font-serif font-normal leading-tight md:leading-snug text-h2/m lg:text-h3',
  h4: 'font-serif font-normal leading-tight md:leading-snug text-h4/m lg:text-h4',
  h5: 'font-serif font-normal leading-tight md:leading-snug text-h5',
  h6: 'font-serif font-normal leading-tight md:leading-snug text-h6',
}

const colors = {
  primary: 'text-primary',
  secondary: 'text-secondary',
  white: 'text-white'
}

const Heading = ({ variant, component, color, classes, children }) => {
  let HeadingComponent = null
  const fontClasses = component ? `${font[component]}` : `${font[variant]}`
  const colorClasses = color ? `${colors[color]}` : ''
  const headingClasses = `${fontClasses} ${colorClasses} ${classes}`
  switch (variant) {
    case 'h1':
      HeadingComponent = () => (
        <h1 className={headingClasses}>{children}</h1>
      )
      break
    case 'h2':
      HeadingComponent = () => (
        <h2 className={headingClasses}>{children}</h2>
      )
      break
    case 'h3':
      HeadingComponent = () => (
        <h3 className={headingClasses}>{children}</h3>
      )
      break
    case 'h4':
      HeadingComponent = () => (
        <h4 className={headingClasses}>{children}</h4>
      )
      break
    case 'h5':
      HeadingComponent = () => (
        <h5 className={headingClasses}>{children}</h5>
      )
      break
    case 'h6':
      HeadingComponent = () => (
        <h6 className={headingClasses}>{children}</h6>
      )
      break
    default:
      HeadingComponent = () => (
        'Variant doesnt exists'
      )
      break
  }

  return (
    <HeadingComponent />
  )
}


Heading.propTypes = {
  variant: PropTypes.string,
  component: PropTypes.string,
  color: PropTypes.string,
  classes: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Heading.defaultProps = {
  variant: ``,
  component: ``,
  color: ``,
  classes: ``,
  children: ``,
}

export default Heading
