import React, { useEffect, useState, useRef } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Heading from "@atoms/Heading"
import Button from "@atoms/Button"
import CategoryItem from "@atoms/CategoryItem"
import CustomerBox from "@molecules/CustomerBox"
import RangeItem from "@molecules/RangeItem"
import SimulatorCompletePopUp from "@molecules/SimulatorCompletePopUp"
import BackIcon from "@svg/arrow-right-next.svg"
import InfoIcon from "@svg/info.svg"
import SettingsIcon from "@svg/settings.svg"
import SimulatorForm from "@organisms/SimulatorForm"
import SimulatorOptions from "@organisms/SimulatorOptions"
import shoppersConfig from "@config/shoppers.json"
import FoxIcon from "@svg/fox.svg"
import LocationIcon from "@svg/location.svg"
import CalendarIcon from "@svg/calendar.svg"
import {
  areSavedOptions,
  checkUser,
  getCategory,
  getIntroCompletedStatus,
  getSavedPromotions, getSavedSettings,
  getUser,
  isBrowser,
  saveCategory,
  saveIntroCompletedStatus,
  savePromotions, saveSettings
} from "@components/helpers/localeStorageTools"
import {
  CATEGORY_OPTIONS,
  DEFAULT_CATEGORY_PRODUCT,
  DEFAULT_CATEGORY_PROMOTIONS,
  DISTANCE,
  LAST_VISIT,
  LOYALTY,
  NEW_CUSTOMER,
  PROMOTION_OPTIONS,
  REFERRAL,
  REVIEW_LOCATION,
  REVIEW_PRODUCT,
  TIME_TO_CLOSE,
  TIME_TO_CLOSE_OPTIONS,
  UNCHANGEABLE_CATEGORY_PROMOTIONS
} from "@config/variables"
import promotions from "@config/promotions"
import "intro.js/introjs.css"
import Select from "react-select"
import selectStyles from "@styles/select.js"
import axios from "axios"
import NumberFormat from 'react-number-format';

let Steps
if (isBrowser) Steps = require("intro.js-react").Steps

const defaultSettings = (category) => {
  const savedValues = getSavedSettings(category)
  return !!savedValues ? savedValues : null
}

const defaultPromotions = (category) => {
  const savedValues = getSavedPromotions(category)
  return !!savedValues ? Object.keys(savedValues) : DEFAULT_CATEGORY_PROMOTIONS[category]
}

const defaultSelectedOptions = (selectedPromotions = [], selectedOptions = {}, category = getCategory()) => {
  const values = {}
  const savedValues = getSavedPromotions(category)
  const oldValues = !!savedValues ? savedValues : selectedOptions

  selectedPromotions.forEach((selectedPromotion) => {
    const p = promotions.find((promotion) => promotion.type === selectedPromotion)
    const oldValue = oldValues[selectedPromotion]
    values[selectedPromotion] = oldValue || p.value
  })
  return values
}

const TooltipContent = () => {
  return (
    <div>
      <h6 className="text-h6 text-text font-serif p-0 mb-4 text-center">Select your business type</h6>
    </div>
  )
}

const TooltipContent2 = () => {
  return (
    <div>
      <h6 className="text-h6 text-text font-serif p-0 mb-4 text-center">Setup Smart Pricing</h6>
      <div className="flex">
        <div
          className="w-9 h-9 rounded-full bg-primary-20 text-primary flex items-center justify-center flex-shrink-0 mr-2 mt-1">
          <SettingsIcon className="fill-current w-5 h-5" />
        </div>
        <p className="text-text text-xs font-serif p-0 mb-4 leading-snug">
          Choose set of smart pricing rules most important for your business
        </p>
      </div>
    </div>
  )
}

const TooltipContent3 = () => {
  return (
    <div>
      <h6 className="text-h6 text-text font-serif p-0 mb-4 text-center">Deliver personalized price every time</h6>
      <p className="text-text text-xs text-center font-serif p-0 mb-4 leading-snug">
        Price is personalized for each shopper based on slider settings
      </p>
      <div className="mb-3">
        <div className="flex items-center py-0.5">
          <FoxIcon className="flex-shrink-0 text-primary fill-current" width="40px" height="40px" />
          <span className="text-xs inline-block ml-1">Tenko points</span>
        </div>
        <div className="flex items-center py-0.5">
          <LocationIcon className="flex-shrink-0 text-primary fill-current" width="40px" height="40px" />
          <span className="text-xs inline-block ml-1">Distance in miles</span>
        </div>
        <div className="flex items-center py-0.5">
          <CalendarIcon className="flex-shrink-0 text-primary fill-current" width="40px" height="40px" />
          <span className="text-xs inline-block ml-1">Days since last visit</span>
        </div>
      </div>
    </div>
  )
}

const SimulatorPage = () => {
  const data = useStaticQuery(graphql`
  query {
    avatars: allFile(
      sort: {fields: name}
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "avatars" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(placeholder: NONE, width: 124, layout: CONSTRAINED)
          }
        }
      }
    }
    products: allFile(
      sort: {fields: name}
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "products" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`)

  const [showOptions, setShowOptions] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(getCategory())
  const [product, setProduct] = useState({})
  const [selectedPromotions, setSelectedPromotions] = useState(defaultPromotions(selectedCategory))
  const [promotionSelectedOptions, setPromotionSelectedOptions] = useState(
    defaultSelectedOptions(selectedPromotions, {}, selectedCategory))
  const [shoppers, setShoppers] = useState(shoppersConfig.shoppers)
  const [showCompletedPopUp, setShowCompletedPopUp] = useState(false)
  const [stepsEnabled, setStepsEnabled] = useState(null)
  const [initialStep, setInitialStep] = useState(0)
  const [timeToCloseOption, setTimeToCloseOption] = useState({})
  const [showPromotions, setShowPromotions] = useState(true)
  const [maxDiscount, setMaxDiscount] = useState(75)
  const suffixReference = useRef(null)
  const [steps, setSteps] = useState([
    {
      element: ".tour1",
      intro: <TooltipContent />,
      position: "top"
    },
    {
      element: ".tour2",
      intro: <TooltipContent2 />,
      position: "right"
    },
    {
      element: ".tour3",
      intro: <TooltipContent3 />,
      position: "left"
    }
  ])

  const onExit = () => {
    setStepsEnabled(false)
    saveIntroCompletedStatus(true)
  }

  const startIntro = () => {
    setStepsEnabled(true)
  }

  useEffect(() => {
    if (!areSavedOptions()) {
      const promotions = DEFAULT_CATEGORY_PROMOTIONS
      const keys = Object.keys(promotions)
      keys.forEach((category) => {
        const selectedOptions = defaultSelectedOptions(promotions[category], {}, category)
        savePromotions(selectedOptions, category)
      })
    }
  }, [])

  useEffect(() => {
    if (!isBrowser) return

    (getCategory())
    setSelectedCategory(getCategory())

    calculatePrice()
    setDefaults()
  }, [])

  function setDefaults() {
    const savedSettings = defaultSettings(selectedCategory)
    setProduct(savedSettings?.product || DEFAULT_CATEGORY_PRODUCT[selectedCategory])
    setMaxDiscount(savedSettings?.maxDiscount || 75)
    setTimeToCloseOption(savedSettings?.timeToCloseOption || TIME_TO_CLOSE_OPTIONS[0])
  }

  useEffect(() => {
    setDefaults()

    setSelectedPromotions(defaultPromotions(selectedCategory))
    setShowPromotions(false)
  }, [selectedCategory])

  useEffect(() => {
    setShowPromotions(false)
    setPromotionSelectedOptions(defaultSelectedOptions(selectedPromotions, promotionSelectedOptions))
  }, [selectedPromotions])

  useEffect(() => {
    setShowPromotions(true)
  }, [promotionSelectedOptions])

  useEffect(() => {
    saveSettings({ product, maxDiscount, timeToCloseOption }, selectedCategory)

    if (Number(maxDiscount) !== 0)
      calculatePrice()
  }, [promotionSelectedOptions, product.price, timeToCloseOption.value, maxDiscount])

  const calculatePrice = () => {
    const newShoppers = shoppers

    newShoppers.map((shopper) => {
      let totalLoyalty = shopper.loyaltyPurchase
      let discount = 0

      const loyaltyPromotions = selectedPromotions.filter(
        (promotion) => [REFERRAL, REVIEW_LOCATION, REVIEW_PRODUCT].includes(promotion))

      loyaltyPromotions.forEach((promotion) => {
        const promotionValue = promotionSelectedOptions[promotion]

        switch (promotion) {
          case REFERRAL:
            totalLoyalty += shopper.referrals * promotionValue
            break
          case REVIEW_LOCATION:
            totalLoyalty += shopper.reviewMerchant ? promotionValue : 0
            break
          case REVIEW_PRODUCT:
            totalLoyalty += shopper.reviewProducts * promotionValue
            break
        }
      })

      selectedPromotions.forEach((promotion) => {
        const promotionValue = promotionSelectedOptions[promotion]

        switch (promotion) {
          case DISTANCE:
            discount += Math.floor(shopper.distance * promotionValue)
            break
          case LAST_VISIT:
            discount += Math.floor(shopper.lastVisit / 10 * promotionValue)
            break
          case LOYALTY:
            discount += Math.floor(totalLoyalty / 1000 * promotionValue)
            break
          case NEW_CUSTOMER:
            discount += shopper.newCustomer ? promotionValue : 0
            break
          case TIME_TO_CLOSE:
            discount += Math.floor((180 - timeToCloseOption.value) / 60 * promotionValue)
            break
        }

        if (discount > maxDiscount) discount = maxDiscount
        shopper.discount = discount
        shopper.totalPoints = totalLoyalty
        shopper.price = (product.price * (100 - discount) / 100).toFixed(2)
      })
    })

    setShoppers([...newShoppers])
    savePromotions(promotionSelectedOptions, selectedCategory)
  }

  const onCategoryItemClick = (option) => {
    saveCategory(option.value)
    setSelectedCategory(option.value)
  }

  const applyPromotions = (promotions) => {
    savePromotions(defaultSelectedOptions(promotions), selectedCategory)
    setSelectedPromotions(promotions)
    setShowOptions(false)
  }

  const switchOffOptions = () => {
    setShowOptions(false)
    calculatePrice()
  }

  const canDisplayTimeToClose = () => {
    return selectedPromotions.includes(TIME_TO_CLOSE)
  }

  function validatePrice(e) {
    let value = (e.target.value)
    let price = value >= 100 ? 99.99 : value

    setProduct({ ...product, price: price })
  }

  function handleIncreaseProductPrice(productPrice) {
    let currentProductPrice = Number(productPrice)
    if (currentProductPrice >= 99) { return }
    setProduct({ ...product, price: (currentProductPrice + 1).toFixed(2) })
  }

  function handleDecreaeProductPrice(productPrice) {
    let currentProductPrice = Number(productPrice)
    if (currentProductPrice <= 1) { return }
    setProduct({ ...product, price: (currentProductPrice - 1).toFixed(2) })
  }

  function validateMaxDiscount(e) {
    let value = (e.target.value)
    let discount = value >= 100 ? 99 : value

    setMaxDiscount(discount)
    suffixReference.current.style.marginLeft = `${discount.length}ch`
  }

  function handleIncreaseMaxDiscount(maxDiscount) {
    let currentDiscount = Number(maxDiscount)
    if (currentDiscount >= 99) { return }
    setMaxDiscount(currentDiscount + 1)
  }

  function handleDecreaeMaxDiscount(maxDiscount) {
    let currentDiscount = Number(maxDiscount)
    if (currentDiscount <= 0) { return }
    setMaxDiscount(currentDiscount - 1)
  }

  function priceInputFocusOut(e) {
    const value = Number(e.target.value)

    setProduct({
      ...product,
      price: value.toFixed(2)
        .toString()
    })
  }

  const promotionRangeItem = (promotion) => {
    const { value, step, min, max, description, unit } = promotions.find((p) => p.type === promotion)
    const promotionLabel = PROMOTION_OPTIONS.find((option) => option.value === promotion).label
    const newPromotionSelectedOptions = promotionSelectedOptions
    const onChange = (value) => {
      newPromotionSelectedOptions[promotion] = value
      setPromotionSelectedOptions(newPromotionSelectedOptions)
      calculatePrice()
    }

    return <div key={promotion} className="mt-4 md:mt-5 lg:mt-3 3xl:mt-3">
      <RangeItem
        onChange={onChange}
        label={promotionLabel}
        description={description}
        defaultValue={promotionSelectedOptions[promotion] || value}
        step={step}
        unit={unit}
        min={min}
        max={max}
      />
    </div>
  }

  const saveAndSendEmail = () => {
    let user = getUser()

    if (!!user) {
      user = JSON.parse(user)
      axios.post(`${process.env.GATSBY_API_URL}/merchants`,
        {
          name: user.name,
          email: user.email,
          category: user.category
        }
      )
        .then(() => setShowCompletedPopUp(true))
    }
  }

  return (
    <Layout hideHeader hideFooter>
      <Seo title="Simulator | Merchant" />
      {isBrowser && <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        options=
        {{
          tooltipClass: "tour-tooltip",
          showProgress: false,
          showStepNumbers: false,
          showBullets: false,
          exitOnOverlayClick: false,
          doneLabel: "Done",
          nextLabel: "Next",
          hideNext: false,
          skipLabel: "Skip info"
        }}
      />}
      <section className="bg-neutral-50 min-h-screen">
        <div className="max-w-screen-2xl mx-auto px-4 py-3 3xl:py-5">
          <div className="text-center relative">
            <Heading variant="h1" component="h4" classes="mb-0">Simulator</Heading>
            <Link
              to="/for-merchants"
              className="text-brand-black hover:text-text-80 transition-colors duration-500 ease-in-out inline-block absolute top-1/2 left-0 transform -translate-y-1/2"
            >
              <BackIcon className="w-7 h-7 fill-current" />
            </Link>
            <button
              className="text-brand-black hover:text-text-80 transition-colors duration-500 ease-in-out inline-block absolute top-1/2 right-0 transform -translate-y-1/2"
            >
              <InfoIcon className="w-7 h-7 fill-current" onClick={startIntro} />
            </button>
          </div>
        </div>
        <div className="max-w-screen-2xl mx-auto px-4">
          <div
            className="md:bg-white md:shadow-sm md:px-4 py-3 3xl:py-4 rounded-3xl flex space-x-4 md:space-x-2 overflow-x-auto no-scrollbar tour1">
            {CATEGORY_OPTIONS.map((option, index) => {
              return <CategoryItem
                key={index}
                onClick={() => onCategoryItemClick(option)}
                label={option.label}
                isSelected={selectedCategory === option.value}
              />
            }
            )}
          </div>
        </div>
        <div className="max-w-screen-2xl mx-auto px-4 md:mt-2 3xl:mt-3">
          <div className="flex flex-wrap justify-between lg:-mx-4">
            <div className="w-full lg:w-6/12 xl:w-5/12 lg:px-4 py-2 lg:py-0 tour2">
              <Heading
                variant="h2"
                component="h6"
                classes="hidden md:block mb-0 py-2 text-neutral-600 border-b-2 border-neutral-200"
              >
                Product base price:
              </Heading>

              <div className="flex items-center mt-2 md:mt-0">
                <div
                  className="md:bg-white md:shadow-sm md:px-5 py-0.5 md:rounded-3xl flex items-center md:mt-4 3xl:mt-6 flex-grow">
                  <div className="hidden md:block flex-shrink-0 p-0.5 md:mr-6">
                    <div className="w-11 h-11 rounded-full bg-neutral-200 overflow-hidden">
                      <GatsbyImage
                        image={getImage(data.products.edges[product.id]?.node)}
                        quality={100}
                        alt={'product.name'}
                      />
                    </div>
                  </div>
                  <div className="flex-grow">
                    <div
                      className="flex items-center justify-between text-secondary text-h6 font-serif">
                      <div className="flex-grow">
                        <span className="inline-block pt-0.5">{product.name}</span>
                      </div>
                      <div className="flex-shrink-0 relative">
                        <span
                          className="absolute top-1/2 left-4 transform -translate-y-1/2 inline-block pt-0.5">$</span>
                        <NumberFormat
                          decimalScale={2}
                          allowNegative={false}
                          value={product.price}
                          onChange={validatePrice}
                          onBlur={priceInputFocusOut}
                          className="appearance-none text-secondary text-3xl font-serif p-0 pl-7 pr-2 py-1 mt-0.5 border-2 border-neutral-100 rounded-2xl inline-flex focus:border-neutral-100 focus:shadow-none focus:ring-0 w-full max-w-full"
                          style={{ width: "132px" }}
                        />
                        {/* <input
                          value={}
                          onChange={validatePrice}
                          onBlur={priceInputFocusOut}
                          step="any"
                          type="number"
                          min="0.01"
                          max="99"
                          className="appearance-none text-secondary text-3xl font-serif p-0 pl-7 pr-2 py-1 mt-0.5 border-2 border-neutral-100 rounded-2xl inline-flex focus:border-neutral-100 focus:shadow-none focus:ring-0 w-full max-w-full"
                          style={{ width: "132px" }}
                        /> */}
                        <div className="number-arrows">
                          <button className="up" onClick={() => handleIncreaseProductPrice(product.price)}>
                            <span className="sr-only">up</span>
                          </button>
                          <button className="down" onClick={() => handleDecreaeProductPrice(product.price)}>
                            <span className="sr-only">down</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center mt-2 md:mt-0">
                <div
                  className="md:bg-white md:shadow-sm md:px-5 py-0.5 md:rounded-3xl flex items-center md:mt-3 flex-grow">
                  <div className="hidden md:block flex-shrink-0 p-0.5 md:mr-6">
                    <div className="w-11 h-11 rounded-full bg-neutral-200 overflow-hidden">
                      <StaticImage
                        src="../images/max-discount.png"
                        width={100}
                        quality={100}
                        alt="Maximum discount"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="flex-grow">
                    <div
                      className="flex items-center justify-between text-secondary text-h6 font-serif">
                      <div className="flex-grow">
                        <span className="inline-block pt-0.5">Maximum discount</span>
                      </div>
                      <div className="flex-shrink-0 relative">
                        <span
                          className="absolute top-1/2 left-3 transform -translate-y-1/2 inline-block pt-0.5"
                          ref={suffixReference}
                          style={{ marginLeft: '2ch', paddingLeft: '3px' }}
                        >
                          %
                        </span>
                        <input
                          value={maxDiscount}
                          onChange={validateMaxDiscount}
                          onBlur={e => setMaxDiscount(e.target.value || 1)}
                          step="any"
                          type="number"
                          min="1"
                          max="100"
                          className="appearance-none text-secondary text-3xl font-serif p-0 pl-3 pr-2 py-1 mt-0.5 border-2 border-neutral-100 rounded-2xl inline-flex focus:border-neutral-100 focus:shadow-none focus:ring-0 w-full max-w-full"
                          style={{ width: "132px" }}
                        />
                        <div className="number-arrows">
                          <button className="up" onClick={() => handleIncreaseMaxDiscount(maxDiscount)}>
                            <span className="sr-only">up</span>
                          </button>
                          <button className="down" onClick={() => handleDecreaeMaxDiscount(maxDiscount)}>
                            <span className="sr-only">down</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`flex items-center mt-2 md:mt-0 ${canDisplayTimeToClose()
                ? "opacity-100 pointer-events-auto"
                : "opacity-60 pointer-events-none select-disabled"}`}>
                <div
                  className="md:bg-white md:shadow-sm md:px-5 py-0.5 md:rounded-3xl flex items-center md:mt-3 flex-grow">
                  <div className="hidden md:block flex-shrink-0 p-0.5 md:mr-6">
                    <div className="w-11 h-11 rounded-full overflow-hidden">
                      <StaticImage
                        src="../images/time-to-close.png"
                        width={100}
                        quality={100}
                        alt="Time to close"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="flex-grow">
                    <div
                      className="flex items-center justify-between text-secondary text-h6 font-serif">
                      <div className="flex-grow">
                        <span className="inline-block pt-0.5">Time to close</span>
                      </div>
                      <div className="flex-shrink-0 relative">
                        <div style={{ width: "132px" }}>
                          <Select
                            options={TIME_TO_CLOSE_OPTIONS}
                            value={timeToCloseOption}
                            onChange={setTimeToCloseOption}
                            style={{ width: "132px" }}
                            styles={selectStyles}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex mt-3 3xl:mt-6  3xl:mb-6 items-center">
                <Heading variant="h2" component="h6"
                  classes="mb-0 pb-2 text-neutral-600 border-b-2 border-neutral-200 flex-grow">
                  Smart pricing rules:
                </Heading>
                <div className="flex-shrink-0 ml-2">
                  <button
                    onClick={() => setShowOptions(true)}
                    className="w-9 h-9 rounded-full bg-primary-20 text-primary flex items-center justify-center">
                    <SettingsIcon className="fill-current w-5 h-5" />
                  </button>
                </div>
              </div>
              {showPromotions && selectedPromotions.map(promotionRangeItem)}
            </div>
            <div className="w-full lg:w-6/12 lg:px-4 tour3">
              <Heading variant="h2" component="h6"
                classes="mt-6 lg:mt-0 mb-0 py-2 text-neutral-600 md:border-b-2 md:border-neutral-200">
                End prices that customers will receive:
              </Heading>
              <div className="flex md:flex-wrap -mx-2 mt:3 md:mt-4 lg:mt-2 3xl:mt-6 overflow-x-auto no-scrollbar">
                {shoppers.map((shopper, index) => {
                  return (
                    <div key={shopper.id} className="md:w-1/3 lg:w-1/2 xl:w-1/3 px-2">
                      <CustomerBox shopper={shopper} maxDiscount={maxDiscount}>
                        <GatsbyImage
                          image={getImage(data.avatars.edges[index]?.node)}
                          quality={100}
                          alt={shopper.name}
                          imgClassName="rounded-full max-w-full"
                        />
                      </CustomerBox>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-screen-2xl mx-auto px-4 py-2 3xl:py-6">
          <div className="text-center">
            <Button
              variant="primary"
              isWide
              handleClick={() => saveAndSendEmail()}
            >
              Send it to me
            </Button>
          </div>
        </div>
      </section>

      <SimulatorForm setSelectedCategory={setSelectedCategory} startIntro={startIntro} />
      {showOptions &&
        <SimulatorOptions
          selectedPromotions={selectedPromotions.filter(
            (promotion) => !UNCHANGEABLE_CATEGORY_PROMOTIONS.includes(promotion))}
          applyPromotions={applyPromotions}
          closeOptions={switchOffOptions}
        />
      }
      {showCompletedPopUp &&
        <SimulatorCompletePopUp head="Done!">
          <div className="prose text-secondary-120">
            <p>
              Email with the link
              <br />
              was sent to your mailbox.
            </p>
          </div>
          <div className="mt-8">
            <Button variant="primary" isWide handleClick={() => setShowCompletedPopUp(false)}>OK</Button>
          </div>
        </SimulatorCompletePopUp>
      }

    </Layout>
  )
}

export default SimulatorPage
