import React from 'react'
import PropTypes from 'prop-types'
import FoxIcon from '@svg/fox.svg'
import LocationIcon from '@svg/location.svg'
import CalendarIcon from '@svg/calendar.svg'

const CustomerBox = ({ shopper, children }) => {
  return (
    <div className='flex flex-col justify-center relative mt-8 3xl:mt-12 mb-3 customer-box'>
      <div className='w-16 h-16 rounded-full shadow-sm absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'></div>
      <div className='rounded-2xl bg-white text-center shadow-sm relative z-10'>
        <div className='absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <div className='w-16 h-16 rounded-full bg-neutral-400 overflow-hidden border-4 border-neutral-25'>
            {children}
          </div>
        </div>
        <div className='pt-10 px-3'>
          <h3 className='text-xs uppercase font-serif font-normal tracking-wide text-center mt-2 mb-2'>{shopper.name}</h3>
          <p className='text-micro text-text-80 leading-snug mb-0'>{shopper.description}</p>
        </div>
        <div className='flex justify-between px-3 py-3'>
          <div className='flex items-center'>
            <FoxIcon className='flex-shrink-0 text-primary fill-current' width='24px' height='24px' />
            <span className='text-xs font-semibold inline-block ml-0.5'>{shopper.totalPoints}</span>
          </div>
          <div className='flex items-center'>
            <LocationIcon className='flex-shrink-0 text-primary fill-current' width='24px' height='24px' />
            <span className='text-xs font-semibold inline-block ml-0.5'>{shopper.distance}mi</span>
          </div>
          <div className='flex items-center'>
            <CalendarIcon className='flex-shrink-0 text-primary fill-current' width='24px' height='24px' />
            <span className='text-xs font-semibold inline-block ml-0.5'>{shopper.lastVisit}d</span>
          </div>
        </div>
        <div className='bg-secondary px-3 py-3 rounded-b-2xl text-white text-center font-serif flex items-center justify-center'>
          <div className='inline-block text-h5 leading-none'>${shopper.price}</div>
          <span className='inline-block w-2 h-2 mx-2 bg-primary rounded-full leading-none'></span>
          <div className='inline-block text-sm leading-none'>{shopper.discount}%
            <span className='inline-block ml-1'>off</span>
          </div>
        </div>
      </div>
    </div>
  )
}

CustomerBox.propTypes = {
  head: PropTypes.string,
  lead: PropTypes.string
}

CustomerBox.defaultProps = {
  head: ``,
  lead: ``
}

export default CustomerBox
