//promotions
export const NEW_CUSTOMER = "newCustomer"
export const LOYALTY = "loyalty"
export const DISTANCE = "distance"
export const TIME_TO_CLOSE = "timeToClose"
export const LAST_VISIT = "lastVisit"
export const REFERRAL = "referral"
export const REVIEW_LOCATION = "reviewLocation"
export const REVIEW_PRODUCT = "reviewProduct"

export const PROMOTION_OPTIONS = [
  { value: NEW_CUSTOMER, label: "New customer" },
  { value: LOYALTY, label: "Loyalty" },
  { value: DISTANCE, label: "Distance" },
  { value: TIME_TO_CLOSE, label: "Time to close" },
  { value: LAST_VISIT, label: "Last visit" },
  { value: REFERRAL, label: "Referral" },
  { value: REVIEW_LOCATION, label: "Review location" },
  { value: REVIEW_PRODUCT, label: "Review product" }
]

//categories
export const RESTAURANT = "restaurant"
export const CAFETERIA = "coffeeShop"
export const BAKERY = "bakery"
export const BOWLING = "bowling"
export const BEAUTY_SALON = "beautySalon"
export const CATEGORIES = [CAFETERIA, RESTAURANT, BAKERY, BOWLING, BEAUTY_SALON]

export const CATEGORY_OPTIONS = [
  { value: CAFETERIA, label: "Cafeteria" },
  { value: RESTAURANT, label: "Restaurant" },
  { value: BAKERY, label: "Bakery" },
  { value: BOWLING, label: "Bowling" },
  { value: BEAUTY_SALON, label: "Beauty salon" }
]

//default promotions for category
export const DEFAULT_CATEGORY_PROMOTIONS = {
  [CAFETERIA]: [LOYALTY, NEW_CUSTOMER, REFERRAL, DISTANCE],
  [RESTAURANT]: [LOYALTY, DISTANCE, LAST_VISIT, REVIEW_LOCATION],
  [BAKERY]: [LOYALTY, TIME_TO_CLOSE, REVIEW_PRODUCT, DISTANCE],
  [BOWLING]: [LOYALTY, NEW_CUSTOMER, LAST_VISIT, REFERRAL],
  [BEAUTY_SALON]: [LOYALTY, REVIEW_LOCATION, LAST_VISIT, REFERRAL],
  [undefined]: []
}

//default product for categories
export const DEFAULT_CATEGORY_PRODUCT = {
  [CAFETERIA]: { id: 0, name: "Cappuccino", price: "5.00" },
  [RESTAURANT]: { id: 1, name: "Pizza Margherita", price: "15.25" },
  [BAKERY]: { id: 2, name: "Dozen Baguette", price: "3.75" },
  [BOWLING]: { id: 3, name: "Hourly Lane Rate", price: "60.00" },
  [BEAUTY_SALON]: { id: 4, name: "French Manicure", price: "35.00" },
  [undefined]: { name: "", price: "0.00" }
}

export const UNCHANGEABLE_CATEGORY_PROMOTIONS = [LOYALTY]

export const TIME_TO_CLOSE_OPTIONS = [
  { value: 30, label: "0:30h" },
  { value: 60, label: "1:00h" },
  { value: 90, label: "1:30h" },
  { value: 120, label: "2:00h" },
  { value: 150, label: "2:30h" },
  { value: 180, label: "3:00h" }
]