import React from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { CATEGORY_OPTIONS } from "@config/variables"
import selectStyles from './styles'

const FormSelect = ({ defaultValue, onSelect }) => {
  return (
    <Select
      styles={selectStyles}
      options={CATEGORY_OPTIONS}
      defaultValue={CATEGORY_OPTIONS.find((option) => option.value === defaultValue)}
      onChange={onSelect}
    />
  )
}

FormSelect.propTypes = {
  url: PropTypes.string
}

FormSelect.defaultProps = {
  url: ``
}

export default FormSelect
