import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Heading from "@atoms/Heading"
import FormSelect from "@atoms/FormSelect"
import CheckIcon from "@svg/check.svg"
import { getIntroCompletedStatus, getUser, saveUser } from "@components/helpers/localeStorageTools"
import { Controller, useForm } from "react-hook-form"

const SimulatorForm = ({ setSelectedCategory, startIntro }) => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const { control, handleSubmit, formState: { errors, isValid }, reset } = useForm(
    { mode: "onChange", reValidateMode: "onChange" }
  )

  useEffect(() => {
    const user = getUser()
    reset({ ...JSON.parse(user) })
  }, [])

  const handleSubmitForm = ({ name, email, category = "restaurant" }) => {
    saveUser(name, email, category)
    setFormSubmitted(true)
    setSelectedCategory(category)
    if (getIntroCompletedStatus())
      startIntro()
  }

  return (
    <section className={`fixed w-screen h-screen inset-0 bg-neutral-600 bg-opacity-80 z-50 ${formSubmitted
      ? "hidden"
      : "block"}`}>
      <div className="absolute bottom-0 left-0 w-full">
        <div className="max-w-screen-xl mx-auto px-6 md:px-4 relative">
          <div className="bg-neutral-100 rounded-t-2.5xl p-8 w-full simulator-form-box">
            <Heading variant="h4" classes="pb-4 border-b-2 border-neutral-200 mb-6">
              Hi, you’re almost there!
            </Heading>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <div className="mb-0">
                <label htmlFor="name" className="block font-bold text-tiny">Name</label>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Please enter your name" }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <input
                        id="name"
                        type="text"
                        placeholder="John Doe"
                        value={value}
                        onChange={onChange}
                        className={`${errors?.name
                          ? "bg-utility-red-20 border-utility-red-20 focus:border-utility-red-20"
                          : "bg-white border-white focus:border-white"} text-xs h-40px px-3 py-1 rounded border w-full focus:border-white outline-none focus:ring-0 placeholder-xs`}
                      />
                      <div className="text-xmicro text-utility-red h-6">{errors?.name?.message}</div>
                    </>
                  )}
                />

              </div>
              <div className="mb-0">
                <label htmlFor="email" className="block font-bold text-tiny">E-mail</label>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Please enter your email",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please enter your email"
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <input
                        id="email"
                        type="text"
                        placeholder="Enter your email address"
                        value={value}
                        onChange={onChange}
                        className={`${errors?.email
                          ? "bg-utility-red-20 border-utility-red-20 focus:border-utility-red-20"
                          : "bg-white border-white focus:border-white"} text-xs h-40px px-3 py-1 rounded border w-full focus:border-white outline-none focus:ring-0 placeholder-xs`}
                      />
                      <div className="text-xmicro text-utility-red h-6">{errors?.email?.message}</div>
                    </>
                  )}
                />

              </div>
              <div className="mb-4">
                <label htmlFor="category" className="block font-bold text-tiny">Branch</label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <FormSelect onSelect={(e) => onChange(e.value)} defaultValue="restaurant" />
                    </>
                  )}
                />

              </div>
              <div className="mb-4">
                <div className="flex items-center">
                  <Controller
                    name="confirmation"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <input onChange={onChange} type="checkbox" id="A3-yes" name="A3-confirmation" value="yes"
                               className="opacity-0 absolute h-5 w-5" />
                        <div
                          className={`bg-white border rounded-md w-5 h-5 flex flex-shrink-0 justify-center items-center cursor-pointer ${errors?.confirmation
                            ? "border-utility-red"
                            : "border-grey-3"} `}>
                          <CheckIcon />
                        </div>
                        <label htmlFor="A3-yes" className={` ${errors?.confirmation
                          ? "text-utility-red"
                          : ""} select-none ml-2 text-xmicro cursor-pointer`}>I consent to receive emails with marketing
                          content from Tenko.</label>
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="py-8 border-t-2 border-neutral-200 text-micro">
                <button
                  disabled={!isValid}
                  type="submit"
                  className={`${isValid
                    ? "opacity-100 pointer-events-auto"
                    : "opacity-60 pointer-events-none"} transition-colors duration-300 ease-in-out inline-flex items-center justify-center rounded-3xl py-6 px-6 text-sm text-body font-bold focus:outline-none bg-secondary-80 hover:bg-opacity-90 text-white w-full`}>
                  Have a test drive now!
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

SimulatorForm.propTypes = {
  quote: PropTypes.string
}

SimulatorForm.defaultProps = {
  quote: ``
}

export default SimulatorForm
