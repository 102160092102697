import React, { useEffect, useState } from "react"
import Heading from "@atoms/Heading"
import Button from "@atoms/Button"
import CloseIcon from "@svg/close.svg"
import InfoIcon from "@svg/info.svg"
import promotions from "@config/promotions"
import { LOYALTY, PROMOTION_OPTIONS, UNCHANGEABLE_CATEGORY_PROMOTIONS } from "@config/variables"

const SimulatorOptions = ({ selectedPromotions, applyPromotions, closeOptions }) => {
  const [selectedOptions, setSelectedOptions] = useState(selectedPromotions)
  const [error, setError] = useState("")

  useEffect(() => {
    setSelectedOptions(selectedPromotions)
  }, [selectedPromotions])

  useEffect(() => {
    if (error.length > 0 && selectedOptions.length === 3)
      setError("")
    else if (error.length > 0)
      setError(errorMessage)
  }, [selectedOptions])

  const togglePromotion = (promotion) => {
    const promotionSelected = selectedOptions.includes(promotion)
    const newSelectedPromotions = selectedOptions

    if (selectedOptions.length === 3 && !promotionSelected)
      return null
    else if (promotionSelected) {
      const index = selectedOptions.indexOf(promotion)
      newSelectedPromotions.splice(index, 1)
    } else
      newSelectedPromotions.push(promotion)

    if (selectedOptions.length < 3) return setError(errorMessage())

    setSelectedOptions([...newSelectedPromotions])
  }

  const onClose = () => {
    closeOptions()
  }

  const onApply = () => {
    if (selectedOptions.length < 3) return setError(errorMessage())

    applyPromotions([LOYALTY, ...selectedOptions])
  }

  const errorMessage = () => {
    if (selectedOptions.length === 0) {
      return `You have to choose at least three pricing rules.`
    } else {
      return `Please select ${3 - selectedOptions.length} more ${selectedOptions.length > 1 ? 'category' : 'categories'}.`
    }
  }

  const promotion = (promotion) => {
    const isSelected = selectedOptions.includes(promotion.type)
    let indexOfSelection = 0
    if (isSelected)
      indexOfSelection = selectedOptions.indexOf(promotion.type) + 1

    return <button
      key={promotion.id} onClick={() => togglePromotion(promotion.type)}
      className="w-full sm:w-1/2 md:w-1/3 px-2 py-2 cursor-pointer"
    >
      <div className={`${isSelected ? "bg-secondary-20" : "bg-secondary-10"} rounded-md p-4 text-center h-full`}>
        <div className="relative px-5">
          <Heading variant="h6" classes="mb-3">
            {PROMOTION_OPTIONS.find((option) => option.value === promotion.type).label} promotion
          </Heading>
          {isSelected &&
            <div
              className="absolute top-1/2 right-0 transform -translate-y-1/2 w-5 h-5 rounded-full border-2 border-secondary-80 flex items-center justify-center">
              <span className="text-micro text-secondary font-semibold">{indexOfSelection}</span>
            </div>}
        </div>
        <div className="prose prose-sm text-secondary-120 leading-normal xl:px-8">
          {promotion.description}
        </div>
      </div>
    </button>
  }

  return (
    <section
      className="flex items-center justify-center fixed w-screen h-screen inset-0 bg-neutral-600 bg-opacity-80 z-50">
      <div className="w-full max-w-screen-xl max-h-screen overflow-y-auto no-scrollbar mx-auto md:px-4 md:py-5">
        <div className="bg-white md:rounded-xl py-4 md:py-7 px-6 md:px-12 w-full">
          <div className="flex items-center justify-between mb-4 pb-5 border-b-2 border-neutral-200">
            <Heading variant="h2" component="h4" classes=" mb-0 p-0">
              Set your smart prices preferences:
            </Heading>
            <button className="transition-opacity duration-500 ease-in-out hover:opacity-80">
              <CloseIcon onClick={onClose} className="flex-shrink-0 ml-4" />
            </button>
          </div>
          <div className="text-neutral-600 font-serif text-tiny flex justify-between items-center mb-2">
            {error ?
              <div className='flex items-center text-error pr-4'>
                <InfoIcon className='w-6 h-6 fill-current flex-shrink-0 mr-2' />
                <div className='text-error text-tiny sm:text-sm leading-none'>
                  {error}
                </div>
              </div>
              :
              <div className='flex items-center'>
                <InfoIcon className='w-6 h-6 fill-current flex-shrink-0 mr-2' />
                <div className='text-secondary text-tiny sm:text-sm leading-none pr-6'>You have to choose at least three pricing rules.</div>
              </div>
            }
            <div className='flex-shrink-0'>
              {selectedOptions.length} of 3
            </div>
          </div>
          <div className="flex flex-wrap -mx-2">
            {promotions.filter((promotion) => !UNCHANGEABLE_CATEGORY_PROMOTIONS.includes(promotion.type))
              .map(promotion)}
          </div>
          <div onClick={onApply} className="mt-6 pt-6 border-t-2 border-neutral-200 flex justify-center">
            <Button variant="primary" isWide disabled={error ? true : false} >Apply</Button>
          </div>
        </div>

      </div>
    </section>
  )
}

export default SimulatorOptions
