//export enum EPromotionType {
//  NewCustomer = 1,
//  Loyalty,
//  Distance,
//  TimeToClose,
//  LastVisit,
//  Referral,
//  ReviewLocation,
//  ReviewProduct
//}

import {
  DISTANCE,
  LAST_VISIT,
  LOYALTY,
  NEW_CUSTOMER,
  REFERRAL,
  REVIEW_LOCATION, REVIEW_PRODUCT,
  TIME_TO_CLOSE
} from "@config/variables"

export default [
  {
    "id": 0,
    "type": NEW_CUSTOMER,
    "description": "Decide how many % of discount you want to give to a customer that visits your place for the first time.",
    "enabled": true,
    "value": 50,
    "step": 5,
    "min": 10,
    "max": 50,
    "unit": "%"
  },
  {
    "id": 1,
    "type": LOYALTY,
    "description": "Decide how many % of discount you want to give for every 1000 Tenko points collected.",
    "enabled": true,
    "value": 2,
    "step": 1,
    "min": 1,
    "max": 30,
    "unit": "%"
  },
  {
    "id": 2,
    "type": DISTANCE,
    "description": "Decide how many % of discount you want to give for every mile covered to your place.",
    "enabled": true,
    "value": 1.0,
    "step": 0.1,
    "min": 0.5,
    "max": 2,
    "unit": "%"
  },
  {
    "id": 3,
    "type": LAST_VISIT,
    "description": "Decide how many % of discount you want to give for each 10 days since the last visit in your place.",
    "enabled": true,
    "value": 3,
    "step": 0.5,
    "min": 1,
    "max": 5,
    "unit": "%"
  },
  {
    "id": 4,
    "type": TIME_TO_CLOSE,
    "description": "Decide how many % of discount you want to give for every hour before closing time of your place.",
    "enabled": false,
    "value": 2,
    "step": 1,
    "min": 1,
    "max": 10,
    "unit": "%"
  },
  {
    "id": 5,
    "type": REFERRAL,
    "description": "Decide how many Tenko points you want to give for every referral of your place.",
    "enabled": true,
    "value": 100,
    "step": 5,
    "min": 10,
    "max": 200,
    "unit": " pts"
  },
  {
    "id": 6,
    "type": REVIEW_LOCATION,
    "description": "Decide how many Tenko points you want to give for every online review of your place.",
    "enabled": true,
    "value": 100,
    "step": 5,
    "min": 10,
    "max": 2500,
    "unit": " pts"
  },
  {
    "id": 7,
    "type": REVIEW_PRODUCT,
    "description": "Decide how many Tenko points you want to give for every online review of your product.",
    "enabled": true,
    "value": 100,
    "step": 5,
    "min": 10,
    "max": 1000,
    "unit": " pts"
  }
]
