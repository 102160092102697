import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

const selectedCssClasses = "border-2 border-primary bg-white"
const unselectedCssClasses = "border border-neutral-300 bg-neutral-25"

const CategoryItem = ({ label, onClick, isSelected }) => {
  const [selectedClass, setSelectedClass] = useState(unselectedCssClasses)

  useEffect(() => {
    setSelectedClass(isSelected ? selectedCssClasses : unselectedCssClasses)
  }, [isSelected])

  return (
    <button
      onClick={onClick}
      className={`rounded-2xl inline-flex justify-center items-center px-4 py-1 flex-grow category-item cursor-pointer ${selectedClass}`}
    >
      <span className="text-xs font-bold">{label}</span>
    </button>
  )
}

CategoryItem.propTypes = {
  label: PropTypes.string
}

CategoryItem.defaultProps = {
  label: ``
}

export default CategoryItem
