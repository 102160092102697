const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0",
    fontSize: "14px",
    color: state.isSelected ? "#343440" : "667085",
    backgroundColor: state.isSelected ? "#D5D5EA" : "",
    padding: 6,
    margin: "0px",
    borderRadius: "0.25rem",
    "&:hover": {
      backgroundColor: "transparent",
      color: state.isSelected ? "#343440" : "#667085"
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#343440",
    fontSize: "12px",
    lineHeight: "34px"
  }),
  control: (provided) => ({
    ...provided,
    border: "1px solid #ffffff",
    backgroundColor: "#ffffff",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #ffffff"
    },
    "&:focus": {
      border: "1px solid #ffffff",
      boxShadow: "none"
    }
  }),
  input: (provided) => ({
    ...provided,
    height: "30px",
    color: "#343440",
    fontSize: "14px",
    lineHeight: "34px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& input": {
      height: "30px",
      color: "#343440",
      fontSize: "16px",
      lineHeight: "34px",
      cursor: "pointer",
      "&:focus": {
        border: "0 !important",
        backgroundColor: "transparent !important",
        boxShadow: "none"
      }
    },
    "& div": {
      height: "30px",
      color: "#343440",
      fontSize: "14px",
      lineHeight: "34px"
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 0.75rem",
    borderRadius: "0.25rem"
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#343440",
    fontSize: "16px",
    lineHeight: 1.25
  }),
  menuPortal: (provided) => ({
    ...provided,
    borderRadius: "0",
    border: "0",
    boxShadow: "none"
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    border: 0,
    boxShadow: "none"
  }),

  menuList: (provided) => ({
    ...provided,
    padding: "8px",
    border: "0",
    borderRadius: "0",
    boxShadow: "none"
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none"
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0",
    border: "1px solid #929292",
    borderRight: "0",
    borderTop: "0",
    content: "\" \"",
    display: "block",
    height: "8px",
    pointerEvents: "none",
    position: "absolute",
    transform: "rotate(-45deg)",
    width: "8px",
    marginTop: "-5px",
    right: "12px",
    top: "50%",
    zIndex: 4,

    "& svg": {
      display: "none"
    }
  })

}

export default selectStyles;