const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0",
    fontSize: "14px",
    color: state.isSelected ? "#272F3E" : "#6D727F",
    backgroundColor: state.isSelected ? "#F4F6F8" : "",
    padding: 6,
    margin: "0px",
    borderRadius: "0.25rem",
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "transparent",
      color: state.isSelected ? "#272F3E" : "#272F3E"
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#343440",
    fontSize: "12px",
    lineHeight: "34px"
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "1rem",
    border: '2px solid #F2F4F7',
    backgroundColor: "#ffffff",
    boxShadow: "none",
    "&:hover": {
      border: '2px solid #F2F4F7',
    },
    "&:focus": {
      border: '2px solid #F2F4F7',
      boxShadow: "none"
    }
  }),
  input: (provided) => ({
    ...provided,
    height: "30px",
    color: "#3E3E78",
    fontSize: "23px",
    lineHeight: "34px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& input": {
      height: "30px",
      color: "#343440",
      fontSize: "16px",
      lineHeight: "34px",
      cursor: "pointer",
      "&:focus": {
        border: "0 !important",
        backgroundColor: "transparent !important",
        boxShadow: "none"
      }
    },
    "& div": {
      height: "30px",
      color: "#343440",
      fontSize: "14px",
      lineHeight: "34px"
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 0.75rem",
    borderRadius: "0.25rem"
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#3E3E78",
    fontSize: "23px",
    height: '30px',
    lineHeight: '32px'
  }),
  menuPortal: (provided) => ({
    ...provided,
    borderRadius: "0",
    border: "0",
    boxShadow: "none"
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '4px',
    border: 0,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
    zIndex: "20"
  }),

  menuList: (provided) => ({
    ...provided,
    padding: "8px",
    border: "0",
    borderRadius: "0",
    boxShadow: "none",
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none"
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0",
    border: "2px solid #3E3E78",
    borderRight: "0",
    borderTop: "0",
    content: "\" \"",
    display: "block",
    height: "8px",
    pointerEvents: "none",
    position: "absolute",
    transform: "rotate(-45deg)",
    width: "8px",
    marginTop: "-5px",
    right: "12px",
    top: "50%",
    zIndex: 4,

    "& svg": {
      display: "none"
    },
    ".select-disabled &&": {
      borderColor: "#D6D6D9"
    }
  })

}

export default selectStyles;