import { RESTAURANT } from "@config/variables"

export const isBrowser = typeof window !== "undefined"

export const saveIntroCompletedStatus = (status) => {
  if (!isBrowser) return

  localStorage.setItem("introCompleted", status)
}

export const getIntroCompletedStatus = () => {
  if (!isBrowser) return

  return !localStorage.getItem("introCompleted")
}

export const saveUser = (name, email, category) => {
  if (!isBrowser) return

  localStorage.setItem("user", JSON.stringify({ name, email, category }))
}

export const getUser = () => {
  if (!isBrowser) return

  return localStorage.getItem("user")
}

export const checkUser = () => {
  if (!isBrowser) return

  return !!localStorage.getItem("user")
}

export const getCategory = () => {
  if (!isBrowser) return
  const user = localStorage.getItem("user")

  if (!!user)
    return JSON.parse(user).category

  return RESTAURANT
}

export const saveCategory = (category) => {
  if (!isBrowser) return

  const user = localStorage.getItem("user")

  let newUser = {}

  if (!!user) {
    newUser = JSON.parse(user)
    newUser.category = category
  } else
    newUser = { category }

  localStorage.setItem("user", JSON.stringify(newUser))
}

export const savePromotions = (promotions, category) => {
  if (!isBrowser) return

  const savedPromotions = localStorage.getItem("savedOptions")
  let categoryWithPromotions = {}

  if (!!savedPromotions)
    categoryWithPromotions = JSON.parse(savedPromotions)

  categoryWithPromotions[category] = promotions

  localStorage.setItem("savedOptions", JSON.stringify(categoryWithPromotions))
}

export const getSavedPromotions = (category) => {
  if (!isBrowser) return

  const savedPromotions = localStorage.getItem("savedOptions")

  if (!!savedPromotions)
    return JSON.parse(savedPromotions)[category]

  return null
}

export const areSavedOptions = () => {
  if (!isBrowser) return

  return !!localStorage.getItem("savedOptions")
}

export const saveSettings = (settings, category) => {
  if (!isBrowser) return

  const savedSettings = localStorage.getItem("savedSettings")
  let categoryWithSettings = {}

  if (!!savedSettings)
    categoryWithSettings = JSON.parse(savedSettings)

  categoryWithSettings[category] = settings

  localStorage.setItem("savedSettings", JSON.stringify(categoryWithSettings))
}

export const getSavedSettings = (category) => {
  if (!isBrowser) return

  const savedSettings = localStorage.getItem("savedSettings")

  if (!!savedSettings)
    return JSON.parse(savedSettings)[category]

  return null
}

export const areSavedSettings = () => {
  if (!isBrowser) return

  return !!localStorage.getItem("savedSettings")
}