import React, { useState } from "react"
import PropTypes from "prop-types"
import Slider, { SliderTooltip } from "rc-slider"
import "rc-slider/assets/index.css"
import "../../../styles/slider.css"

const { Handle } = Slider

const TooltipContent = ({value, info}) => {
  return (
    <span>
      <span className='hidden'>{value}</span>
      {info}
    </span>
  )
}

const RangeItem = ({ label, defaultValue, min, max, step, description, onChange, unit }) => {
  const [rangeValue, setRangeValue] = useState(defaultValue)
  const [extreme, setExtreme] = useState("")
  const handleValue = (value) => {
    onChange(value)
    setRangeValue(value)
    if (value === min) {
      setExtreme("min")
    } else if (value === max) {
      setExtreme("max")
    } else {
      setExtreme("")
    }
  }

  const handle = props => {
    const { value, dragging, index, info, ...restProps } = props
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={<TooltipContent value={value} info={description} />}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps}>
        </Handle>
      </SliderTooltip>
    )
  }

  return (
    <div>
      <Slider step={step} min={min} max={max} defaultValue={defaultValue} handle={handle} onChange={handleValue}
        className={extreme}>
        <div className="absolute top-1/2 left-0 transform -translate-y-1/2 px-3 pointer-events-none">
          <span className="font-serif text-secondary">{label} promotion</span>
        </div>
        <div className="absolute top-1/2 right-0 transform -translate-y-1/2 px-3 pointer-events-none">
          <span className="font-bold text-xs">{rangeValue}{unit}</span>
        </div>
      </Slider>
    </div>
  )
}

RangeItem.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.number
}

RangeItem.defaultProps = {
  label: ``,
  defaultValue: ``
}

export default RangeItem
